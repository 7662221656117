<template>
  <div class="paymentPass">
    <ColorTextBtn @click="openDialog">交易密码设置</ColorTextBtn>
    <el-dialog title="交易密码设置" :visible.sync="dialogVisible" width="30%">
      <br />
      <paymentPassBox :passwordVal="passwordVal" :dialogVisible="dialogVisible"></paymentPassBox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commitPassword" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import paymentPassBox from './paymentPassBox.vue'
import { setPaymentPassword } from '@/api/user'
export default {
  components: {
    paymentPassBox
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      passwordVal: [],
      dialogVisible: false,
      loading: false
    }
  },
  computed: {},
  methods: {
    async commitPassword() {
      const approveConfirmPassword = this.processPaymentPassword()
      if (!approveConfirmPassword) {
        return
      }
      try {
        this.loading = true
        const { code } = await setPaymentPassword({
          approveConfirmPassword,
          id: this.id
        })
        if (code === 0) {
          this.$message.success('设置支付密码成功')
        } else {
          this.$message.error('操作失败')
        }
      } catch {}
      this.loading = false
      this.dialogVisible = false
    },
    openDialog() {
      this.dialogVisible = true
      this.passwordVal = []
    },
    processPaymentPassword() {
      let pwd = ''
      this.passwordVal
        .filter((item) => {
          return item !== ''
        })
        .map((item) => {
          pwd += item
        })
      if (pwd.length !== 6) {
        this.$message.warning('请输入完整密码')
        return undefined
      } else {
        return pwd
      }
    }
  }
}
</script>

<style scoped lang="scss">
.paymentPass {
  display: inline-block;
  ::v-deep .el-dialog__header {
    text-align: left;
  }
}
</style>
