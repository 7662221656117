<template>
  <div class="app-container">
    <eHeader
      v-p="['externaladmin:system:user:add', 'externaladmin:system:user:search']"
      :menus="distors"
      :sup_this="sup_this"
      :query="query"
    />

    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <!--表格渲染-->
    <CommonTable
      height="calc(100vh - 288px)"
      :tableLoading="tableLoading"
      :cols="cols"
      :infoData="data"
      :selection="false"
      :isExpand="true"
      @expand-change="expandChange"
      @selection-change="handleSelectionChange"
    >
      <!-- ----展开slot -->
      <template v-p="['externaladmin:system:user:list:getInf']" #expand="{ scoped: row }">
        <div v-if="!showExpand[`${row.id}`]">该管理员无子账号</div>
        <div v-else>
          <el-table :data="expandData[`${row.id}`]" border v-loading="loading">
            <el-table-column label="子账号名称" prop="nickName"> </el-table-column>
            <el-table-column label="登录账号" prop="accountName"> </el-table-column>
            <el-table-column label="激活状态" prop="isDisabled">
              <template v-slot="{ row }">
                <el-tag v-if="row.isDisabled" size="small" type="primary"> 是 </el-tag>
                <el-tag v-else size="small" type="danger"> 否 </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="menu">
              <template v-slot="{ row }">
                <disabledBtn :data="row"></disabledBtn>
                <span class="seperateline"></span>
                <updatePass v-p="['externaladmin:system:user:changePassword']" :data="row" :sup_this="sup_this" />
                <span class="seperateline"></span>
                <paymentPass :id="row.id" v-p="['externaladmin:system:user:setPaymentPassword']"></paymentPass>
              </template>
            </el-table-column>
          </el-table>
          <PaginationBar
            :page="expandPage[row.id].pageIndex"
            :total="expandPageTotal[row.id]"
            @refreshTableEventFun="expandRefreshTableEventFun($event, row)"
          />
        </div>
      </template>
      <!-- ---- -->
      <template #typeSlot="{ scoped: row }">
        {{ row.type_name }}
      </template>

      <template #is_activeName="{ scoped: row }">
        <el-tag v-if="row[row.prop]" size="small" type="primary"> 是 </el-tag>
        <el-tag v-else size="small" type="danger"> 否 </el-tag>
      </template>
      <template #rolesSlot="{ scoped: row }">
        <el-tag
          style="margin: 5px"
          type="primray"
          size="mini"
          :key="id"
          v-for="{ id, roleName } in row[row.prop] || []"
        >
          {{ roleName }}
        </el-tag>
      </template>

      <slot>
        <el-table-column label="操作" width="420px" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.isSuperUser === 0">
              <disabledBtn v-p="['externaladmin:system:user:freeze']" :data="scope.row"></disabledBtn>
              <el-divider style="margin: 0 15px 0 15px" direction="vertical"></el-divider>
              <edit v-p="['externaladmin:system:user:edit']" :menus="distors" :data="scope.row" :sup_this="sup_this" />
              <updatePass v-p="['externaladmin:system:user:changePassword']" :data="scope.row" :sup_this="sup_this" />
              <Assign
                v-p="['externaladmin:system:user:assign']"
                :menus="adminRoleList"
                :data="scope.row"
                :sup_this="sup_this"
              />
              <paymentPass :id="scope.row.id" v-p="['externaladmin:system:user:setPaymentPassword']"></paymentPass>
            </template>
            <template v-else>
              <color-text-btn type="warning"> 超级管理员 </color-text-btn>
            </template>
          </template>
        </el-table-column>
      </slot>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import initDataMixin from '@/mixins/initData'
import { subAccount, getAcountChild } from '../../user/resellerPage/resellerAPI'
import { parseTime } from '@/utils'
import paymentPass from './paymentPass'
import eHeader from './module/header'
import edit from './module/edit'
import disabledBtn from '@/views/components/disabledBtn'
import updatePass from './module/updatePass'
import Assign from './module/assign'
import cols from './cols'
import { disabled } from '@/api/user'
import { mapGetters } from 'vuex'

export default {
  name: 'systemUser',
  mixins: [initDataMixin],
  components: { eHeader, edit, updatePass, Assign, disabledBtn, paymentPass },
  data() {
    return {
      cols,
      delLoading: false,
      url: '/externaladmin/authService/user/adminUserList',
      method: 'post',
      sup_this: this,
      distors: [],
      treeIds: [],
      disabledBtnStatus: {
        0: {
          text: '启用',
          type: 'text'
        },
        1: {
          text: '禁用',
          type: 'danger'
        },
        loading: {}
      },
      expandData: {},
      loading: false,
      showExpand: {},
      expandPage: {},
      expandPageTotal: {}
    }
  },
  computed: {
    ...mapGetters(['adminRoleList'])
  },
  methods: {
    parseTime,
    initCallBack() {
      this.treeIds.map((id) => {
        const node = this.$refs.table.table['store'].states.treeData[id]
        node.loaded = false
        node.expanded = false
      })
    },
    async handleDisabled({ id, isDisabled }) {
      this.$set(this.disabledBtnStatus.loading, id, true)
      let res = await awaitResolve(
        disabled({
          id,
          isDisabled: isDisabled ? 0 : 1
        })
      )
      if (res) {
        this.$message.success('操作成功')
        this.refreshPage()
      }
      this.disabledBtnStatus.loading[id] = false
    },
    // ---------------------fhj添加
    async expandChange(row) {
      console.log(row)
      if (typeof this.showExpand[row.id] === 'boolean') return
      this.showExpand[row.id] = true
      this.loading = true
      if (!this.expandPage[row.id])
        this.$set(this.expandPage, row.id, {
          pageIndex: 1,
          pageSize: 10
        })
      const res = await awaitResolve(
        getAcountChild({
          mainAccountUid: row.id
        })
      )
      console.log('res', res)
      if (res) {
        let { detail, page } = res
        this.expandData[row.id] = detail
        if (!detail.length) {
          this.showExpand[row.id] = false
        }
        this.$set(this.expandPageTotal, row.id, page.total)
      }
      this.loading = false
    },

    expandRefreshTableEventFun({ page, size }, row) {
      this.expandPage[row.id].pageIndex = page
      this.expandPage[row.id].pageSize = size
      this.showExpand[row.id] = 1
      this.expandChange(row)
    }
    // ---------------------fhj添加
  }
}
</script>

<style lang="scss" scoped>
.seperateline {
  display: inline-block;
  margin: 0 10px;
  width: 1px;
  height: 10px;
  background-color: #ece8e8;
}
</style>
